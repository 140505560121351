import { useEffect } from "react";
import { useRouter } from "next/navigation";

type RedirectOptions = {
    /** If false, will not redirect (default: true) */
    condition?: boolean;
    /** If true, replaces the current url in history upon redirect, rather than adding (default: true) */
    replace?: boolean;
};

/** Redirects to the given url ASAP. If a condition is specified, only redirects when it is true. */
export const useRedirect = (urlTo: string, options: RedirectOptions = {}): void => {
    const { condition = true, replace = true } = options;
    const router = useRouter();
    useEffect(() => {
        if (!condition) return;
        if (replace) router.replace(urlTo);
        else router.push(urlTo);
    }, [condition]);
};
