import { useParams } from "next/navigation";
import {
    getContentfulServerSideProps,
    StaticPageOr404,
    StaticPageOr404Props
} from "components/contentful/StaticPageOr404";
import { URI_COMMUNITY_GUIDELINES, URI_POLICIES } from "constants/urls";
import { useRedirect } from "hooks/useRedirect";
import Loader from "components/common/Loader";

export { getContentfulServerSideProps as getServerSideProps };

export default function ContentfulPageOr404(props: StaticPageOr404Props) {
    const contentfulSlug = useParams()?.contentfulStaticPageSlug;
    // Redirect /policies and /community-guidelines to /policies/community-guidelines
    const redirectToCommunityGuidelines =
        !!contentfulSlug &&
        [URI_POLICIES, URI_COMMUNITY_GUIDELINES].some(
            url => url.slice(url.lastIndexOf("/") + 1) === contentfulSlug
        );

    useRedirect(URI_COMMUNITY_GUIDELINES, { condition: redirectToCommunityGuidelines });
    if (redirectToCommunityGuidelines) return <Loader />;

    return <StaticPageOr404 {...props} />;
}
